import "./App.css";
import { Switch, Route } from "react-router";
import Navigation from "./components/Navigation";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { AuthProvider } from "./context/AuthContext";
import { ListProvider } from "./context/ListContext";

/* TODO: Import the auth and list context providers from their respective files */

function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <ListProvider>
          <Navigation />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/login' exact component={Login} />
            <Route path='/register' exact component={Signup} />
          </Switch>
        </ListProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
