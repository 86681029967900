/* TODO: Remember to import any required hooks from the react library */
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";

const SignupForm = (props) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (confirmPass !== password) alert("Password does not match");
    else props.signup({ name: name, email: email, password: password });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-group m-3'>
        <label>Name</label>
        <input
          name='name'
          type='text'
          className='form-control'
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className='form-group m-3'>
        <label>Email</label>
        <input
          name='email'
          type='email'
          className='form-control'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className='form-group m-3'>
        <label>Password</label>
        <input
          name='password'
          type='password'
          className='form-control'
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className='form-group m-3'>
        <label>Confirm Password</label>
        <input
          name='password2'
          type='password'
          className='form-control'
          required
          value={confirmPass}
          onChange={(e) => setConfirmPass(e.target.value)}
        />
      </div>
      <button type='submit' className='btn btn-dark'>
        Sign Up
      </button>
    </form>
  );
};

export default SignupForm;
