/* TODO: Remember to import the context hook from the react library */
import React from "react";
import SignupForm from "../components/SignupForm";
import { AuthContext } from "../context/AuthContext";

const Signup = ({ history }) => {
  const auth = React.useContext(AuthContext);

  const signupUser = (user) => {
    user.id = Math.random().toString();
    try {
      /* TODO: Register the user using the context variable */
      auth.register(user);
      history.push("/");
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div>
      <SignupForm signup={signupUser} />
    </div>
  );
};

export default Signup;
