import React from "react";
import ListItem from "./ListItem";

import "bootstrap/dist/css/bootstrap.min.css";

const List = (props) => {
  return (
    <div>
      {props.list !== undefined && (
        <div className='d-flex flex-column justify-content-center'>
          {props.list.map((listItem) => (
            <ListItem key={listItem.id} item={listItem} />
          ))}
        </div>
      )}
    </div>
  );
};

export default List;
