/* TODO: Remember to import any required hooks from the react library */
import React, { useEffect } from "react";
import List from "../components/List";
import { AuthContext } from "../context/AuthContext";
import { ListContext } from "../context/ListContext";

/* TODO: Import the auth and list context from their respective files */

import "bootstrap/dist/css/bootstrap.min.css";

const Home = (props) => {
  /* TODO: Create context variables for both auth and list contexts */
  const auth = React.useContext(AuthContext);
  const list = React.useContext(ListContext);
  const [newItem, setNewItem] = React.useState("");
  const [listValue, setListValue] = React.useState([]);

  useEffect(() => {
    if (auth.state.user === null) {
      props.history.push("/login");
    } else {
      let l = list.getItems(auth.state.user.email);
      setListValue(l);
    }
  }, [list, auth, props]);

  /* TODO: Set up a state for the content of the todo item which 
		 is entered by the user */

  /* TODO: Set up a state for the todo list variable that is passed
		to the list component */

  // TODO
  /* If the user is not authenticated, you should redirect
	 	them to the login page. To do this, you can use an effect
	 	to check when the authentication changes */
  /* If the user is authenticated, get the user's items by 
		using the list context and passing the user's id to filter
		out their items */
  // Redirection: to redirect the user to a url, use < props.history.push(url) >

  /* TODO: Set up a function to change the state for the content */

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(newItem);
    list.addItem(auth.state.user, newItem);
    setNewItem("");
  };

  return (
    <div>
      <form className='m-3' onSubmit={handleSubmit}>
        <input
          className='form-control inline m-2'
          type='text'
          placeholder='Enter a todo item'
          required
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
        />
        <button className='btn btn-light'>ADD</button>
      </form>
      <List list={listValue} />
    </div>
  );
};

export default Home;
