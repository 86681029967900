import React from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

import "bootstrap/dist/css/bootstrap.min.css";

const Navigation = () => {
  const auth = React.useContext(AuthContext);

  const handleLogout = () => auth.logout();

  const logoutButton = (
    <li className='float-right'>
      <button className='btn btn-ligt' onClick={handleLogout}>
        Logout
      </button>
    </li>
  );

  const authLinks = (
    <>
      <li className='nav-item'>
        <NavLink className='nav-link' exact to='/login'>
          Login
        </NavLink>
      </li>
      <li className='nav-item'>
        <NavLink className='nav-link' exact to='/register'>
          Register
        </NavLink>
      </li>
    </>
  );

  return (
    <header>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <a className='navbar-brand m-2' href='/'>
          Todo List
        </a>
        <ul className='navbar-nav position-absolute end-0 m-2'>
          {auth.state.authenticated && logoutButton}
          {!auth.state.authenticated && authLinks}
        </ul>
      </nav>
    </header>
  );
};

export default Navigation;
