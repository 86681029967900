/* TODO: Remember to import any required hooks from the react library */
import React from "react";
import { AuthContext } from "../context/AuthContext";
import { ListContext } from "../context/ListContext";

/* TODO: Import the list context from the context file */

import "bootstrap/dist/css/bootstrap.min.css";

const ListItem = ({ item }) => {
  const { id, content } = item;

  /* TODO: Create a context variable for the list context */
  const auth = React.useContext(AuthContext);
  const list = React.useContext(ListContext);

  const handleDelete = () => {
    /* TODO: Use the list context's remove item function 
		 to remove the list item by passing in the id */
    list.removeItem(auth.state.user, id);
  };

  return (
    <div className='card m-3' id={id}>
      <div className='card-body d-flex justify-content-between align-items-center'>
        <p>{content}</p>
        <button className='btn btn-light' onClick={handleDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default ListItem;
