/* TODO: Remember to import any required hooks from the react library */
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";

const LoginForm = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    props.login({ email: email, password: password });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-group m-3'>
        <label>Email</label>
        <input
          name='email'
          type='email'
          className='form-control'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className='form-group m-3'>
        <label>Password</label>
        <input
          name='password'
          type='text'
          className='form-control'
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type='submit' className='btn btn-dark' onClick={handleSubmit}>
        Login
      </button>
    </form>
  );
};

export default LoginForm;
