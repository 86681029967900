// Remember to import any required hooks from the react library
import React from "react";
import LoginForm from "../components/LoginForm";
import { AuthContext } from "../context/AuthContext";

const Login = ({ history }) => {
  const auth = React.useContext(AuthContext);

  const loginUser = (user) => {
    try {
      auth.login(user);
      history.push("/");
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div>
      <LoginForm login={loginUser} />
    </div>
  );
};

export default Login;
